export default [
  {
    header: "Project Management",
    route: "apps-project-header",
  },
  {
    title: "Projects",
    icon: "FileTextIcon",
    route: "apps-projects-folder",
    children: [
      {
        title: "Cards",
        route: "apps-projects-cards",
      },
      {
        title: "Project List",
        route: "apps-project-list",
      },
    ],
  },
  {
    title: "message.tasks.tasks",
    icon: "CheckSquareIcon",
    route: "apps-tasks-folder",
    children: [
      {
        title: "Cards",
        route: "apps-task-cards",
      },
      {
        title: "message.tasks.taskList",
        route: "apps-task-list",
      },
      {
        title: "Kanban Board",
        route: "apps-kanban",
      },
      {
        title: "Bugs",
        route: "apps-bugs",
      },
    ],
  },
  {
    title: "message.sprints.sprints",
    icon: "GridIcon",
    route: "apps-sprint-folder",
    children: [
      {
        title: "Cards",
        route: "apps-sprint-cards",
      },
      {
        title: "message.sprints.sprintList",
        route: "apps-sprint-list",
      },
    ],
  },
  {
    title: "message.products.products",
    icon: "BoxIcon",
    route: "apps-product-folder",
    children: [
      {
        title: "Cards",
        route: "apps-product-cards",
      },
    ],
  },
  {
    title: "TimeSheet",
    icon: "ClockIcon",
    route: "apps-time-sheet-list",
  },
];
