export default [
  {
    header: "Business",
    route: "apps-business-header",
  },
  {
    title: "message.opportunities.opportunities",
    icon: "TargetIcon",
    route: "apps-opportunity-folder",
    children: [
      {
        title: "message.opportunities.listOfOpportunities",
        route: "apps-opportunity-list",
      },
    ],
  },

  {
    title: "message.contracts.contracts",
    icon: "FolderIcon",
    route: "apps-contracts-folder",
    children: [
      {
        title: "message.contracts.contractList",
        route: "apps-contract-list",
      },
    ],
  },

  {
    title: "message.Organization.organization",
    icon: "UsersIcon",
    route: "apps-organization-folder",
    //
    children: [
      {
        title: "message.Organization.listOfOrganization",
        route: "apps-organization-list",
      },
      {
        title: "message.Organization.organizationDetails",
        route: "apps-organization-details",
      },      
    ],
  },

  {
    title: "message.invoices.invoices",
    icon: "BookmarkIcon",
    route: "apps-invoices-folder",
    children: [
      {
        title: "message.invoices.invoiceList",
        route: "apps-invoice-list",
      },
    ],
  },
  {
    title: "message.bills.bills",
    icon: "DollarSignIcon",
    route: "apps-bills-folder",
    children: [
      {
        title: "message.bills.billList",
        route: "apps-bill-list",
      },
      {
        title: "message.banks.bankList",
        route: "apps-bank-list",
      },
      {
        title: "message.accounts.accountList",
        route: "apps-account-list",
      },
    ],
  },
  {
    title: "message.expenses.expenses",
    icon: "ArchiveIcon",
    route: "apps-expenses-folder",
    children: [
      {
        title: "message.expenses.expenseList",
        route: "apps-expense-list",
      },
    ],
  },

  /*
  {
    title: 'Report',
    icon: 'BookOpenIcon',
    children: [
      {
        title: 'File',
        route: 'apps-expense-list',
      },
    ],
  },
  */

  {
    title: "message.charts.charts",
    icon: "PieChartIcon",
    route: "apps-charts-folder",
    children: [
      {
        title: "message.charts.turnover",
        route: "apps-chart-customer-turnover",
      },
      {
        title: "message.charts.billToReceive",
        route: "apps-chart-invoices",
      },
      {
        title: "message.charts.contractsToReceive",
        route: "apps-chart-contracts-to-receive",
      },
      {
        title: "message.charts.projects",
        route: "apps-chart-project-evolution",
      },
      {
        title: "message.charts.expenses",
        route: "apps-chart-expenses",
      },
      {
        title: "message.charts.cashFlow",
        route: "apps-chart-cash-flow",
      },
      {
        title: "message.charts.contractResult",
        route: "apps-chart-contract-result",
      },
      {
        title: "message.charts.hours_late",
        route: "apps-chart-timesheet-calendar",
      },
      {
        title: "message.charts.opportunity",
        route: "apps-chart-opportunity-status",
      },
      {
        title: "message.charts.accounts",
        route: "apps-chart-accounts",
      },
      {
        title: "message.charts.data",
        route: "apps-chart-data-status",
      },      
    ],
  },

  {
    title: "message.assets.assets",
    icon: "PackageIcon",
    route: "apps-assets-folder",
    children: [
      {
        title: "message.assets.assetList",
        route: "apps-asset-list",
      },
    ],
  },
];
