export default [
  {
    header: "System",
    route: "apps-system-header",
  },
  {
    title: "message.wiki.wiki",
    route: "apps-wiki",
    icon: "GlobeIcon",
  },
  {
    title: "commands",
    route: "apps-command",
    icon: "FolderPlusIcon",
  },
  {
    title: "Activities",
    route: "apps-audit-list",
    icon: "FileTextIcon",
  },
  {
    title: "message.params.parameters",
    route: "apps-param",
    icon: "SettingsIcon",
  },
  {
    title: "Jobs",
    route: "apps-jobs-list",
    icon: "CodesandboxIcon",
  },
  {
    title: "Configuration",
    icon: "ToolIcon",
    route: "apps-config-folder",
    children: [
      {
        title: "message.accessControl.accessControl",
        route: "apps-access-control",
      },
      {
        title: "message.accessControl.functionalities",
        route: "apps-feature-list",
      },
    ],
  },
];
