export default [
  {
    header: "Database",
    route: "apps-database-header",
  },
  {
    title: "message.database.purge",
    icon: "DatabaseIcon",
    route: "apps-database-purge",
  },
];
