export default [
  {
    header: "People",
    route: "apps-people-header",
  },
  {
    title: "Employees",
    icon: "TruckIcon",
    route: "apps-employees-folder",
    children: [
      {
        title: "Employee Cards",
        route: "apps-employees-cards",
      },
      {
        title: "Employee List",
        route: "apps-employees-list",
      },
    ],
  },
  {
    title: "point_management",
    icon: "ListIcon",
    route: "apps-point-folder",
    children: [
      {
        title: "pointList",
        route: "apps-point-list",
      },
    ],
  },
  {
    title: "message.team.teamList",
    icon: "UsersIcon",
    route: "apps-teams-folder",
    children: [
      {
        title: "message.team.teamList",
        route: "apps-team-list",
      },
    ],
  },
  {
    title: "message.user.user",
    icon: "UserIcon",
    route: "apps-user-folder",
    children: [
      {
        title: "Diary",
        route: "apps-diary-list",
      },
      {
        title: "User List",
        route: "apps-users-list",
      },
    ],
  },
  /*{
    title: 'Chat',
    route: 'apps-diary-list',
    icon: 'MessageCircleIcon',
  },*/
  {
    title: "Notice",
    route: "apps-notice-list",
    icon: "MessageSquareIcon",
  },
];
