<template>
  <div>
    <b-nav-item-dropdown
      class="dropdown-notification mr-25"
      :menu-class="[
        showDropDown ? 'dropdown-menu-media show' : 'dropdown-menu-media',
      ]"
      right
    >
      <template #button-content>
        <feather-icon
          :badge="notificationData.length || ''"
          badge-classes="bg-danger"
          class="text-body"
          icon="BellIcon"
          size="21"
        />
      </template>

      <!-- Header -->
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">
            {{ $t("message.bookmark.notification") }}
          </h4>
          <!--<b-badge pill variant="light-primary"> {{notificationData.length}}</b-badge>-->
        </div>
      </li>

      <!-- Notifications -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
      >
        <!-- Account Notification -->
        <b-link
          v-for="notification in notifications"
          v-show="notifications.length > 0"
          :key="notification.id"
          class="cd"
        >
          <b-media>
            <template #aside>
              <b-avatar size="32" :variant="notification.color">
                <feather-icon :icon="notification.icon" />
              </b-avatar>
            </template>
            <p class="media-heading">
              <span class="font-weight-bolder">
                {{ notification.name }}
              </span>

              <b-link
                v-if="notification.type === 'Task'"
                v-b-modal.modal-view-task-dashboard
                @click="setNotificationData(notification)"
              >
                <b-badge pill variant="light-primary" class="float-right cp">
                  <feather-icon icon="InfoIcon" />
                </b-badge>
              </b-link>

              <b-link
                v-if="notification.type === 'Notice'"
                v-b-modal.modal-view-notice-dashboard
                @click="setNotificationData(notification)"
              >
                <b-badge pill variant="light-primary" class="float-right cp">
                  <feather-icon icon="InfoIcon" />
                </b-badge>
              </b-link>
            </p>
            <small
              class="notification-text"
              v-html="notification.description.slice(0, 45) + '...'"
            ></small>
          </b-media>
        </b-link>

        <b-link v-show="notifications.length === 0">
          <b-media class="cd align-middle">
            <p class="media-heading cd">
              <span class="font-weight-bolder cd" style="margin-left: 125px">
                {{ $t("message.bookmark.noNotifications") }}
              </span>
            </p>
          </b-media>
        </b-link>
      </vue-perfect-scrollbar>
    </b-nav-item-dropdown>

    <b-modal
      id="modal-view-task-dashboard"
      modal-class="modal-primary"
      centered
      size="lg"
      title="Tarefa"
      ok-only
      ok-title="Ver"
      @ok="loadTask()"
    >
      <b-row>
        <b-col cols="12" md="10" style="font-weight: bold">
          {{ $t("message.list.title") }}
          <h5 style="font-weight: normal">
            {{ notificationExpandedData.name }}
          </h5>
        </b-col>
        <b-col cols="12" md="2" style="font-weight: bold">
          {{ $t("message.projects.endDate") }}
          <h5 style="font-weight: normal">
            {{ localeDate(notificationExpandedData.date) }}
          </h5>
        </b-col> </b-row
      ><br />
      <b-row>
        <b-col cols="12" md="12" style="font-weight: bold">
          {{ $t("message.projects.description") }}
          <h5 style="font-weight: normal">
            <b-alert variant="secondary" show>
              <div class="alert-body">
                <span v-html="notificationExpandedData.description"></span>
              </div>
            </b-alert>
          </h5>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="modal-view-notice-dashboard"
      modal-class="modal-primary"
      centered
      size="lg"
      title="Comunicado"
      ok-only
      ok-title="Ok"
    >
      <b-row>
        <b-col cols="12" md="10" style="font-weight: bold">
          {{ $t("message.list.title") }}
          <h5 style="font-weight: normal">
            {{ notificationExpandedData.name }}
          </h5>
        </b-col>
        <b-col cols="12" md="2" style="font-weight: bold">
          {{ $t("message.list.date") }}
          <h5 style="font-weight: normal">
            {{ localeDate(notificationExpandedData.date) }}
          </h5>
        </b-col> </b-row
      ><br />

      <b-alert variant="secondary" show>
        <div class="alert-body">
          <span v-html="notificationExpandedData.description"></span>
        </div>
      </b-alert>
    </b-modal>
  </div>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
  BCardText,
  BRow,
  BCol,
  BForm,
  BCard,
  BAlert,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import moment from "moment";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import store from "@/store";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    BCardText,
    BRow,
    BCol,
    BForm,
    BCard,
    BAlert,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      showDropDown: false,
      notificationData: [],
      notifications: [],
      notificationExpandedData: [],

      perfectScrollbarSettings: {
        maxScrollbarLength: 80,
        wheelPropagation: false,
      },

      //CurrentUser
      userData: store.state.user.userData,
    };
  },

  async created() {
    await axios
      .get(`${URL_API}notification/${this.userData.id}`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        this.notificationData = response.data;
      });

    for (var i = 0; i < this.notificationData.length; i++) {
      this.notifications.push({
        name: this.notificationData[i].name,
        description: this.notificationData[i].description,
        date: this.notificationData[i].date,
        objectId: this.notificationData[i].objectId,
        members: this.notificationData[i].members,
        type: this.notificationData[i].type,
        color: this.colorType(this.notificationData[i].type),
        icon: this.iconType(this.notificationData[i].type),
      });
    }
  },

  methods: {
    setNotificationData(item) {
      this.notificationExpandedData = item;
    },

    colorType(type) {
      if (type === "Task") {
        return "light-success";
      } else if (type === "Notice") {
        return "light-primary";
      } else {
        return "light-secondary";
      }
    },

    iconType(type) {
      if (type === "Task") {
        return "CheckSquareIcon";
      } else if (type === "Notice") {
        return "MessageSquareIcon";
      } else {
        return "AlertCircleIcon";
      }
    },

    localeDate(value) {
      if (value) {
        return this.$i18n.d(
          new Date(value.split("-")),
          "short",
          this.$i18n.locale
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    loadTask() {
      this.$router.push({ name: "apps-kanban" });
    },
  },
};
</script>

<style>
.cd {
  cursor: default !important;
}

.cp {
  cursor: pointer !important;
}
</style>
