export default [
  {
    header: "Companies",
    route: "apps-companies-header",
  },
  {
    title: "message.customers.customers",
    icon: "BriefcaseIcon",
    route: "apps-customers-folder",
    children: [
      {
        title: "message.customers.clientList",
        route: "apps-customer-list",
      },
    ],
  },

  {
    title: "message.licenses.title",
    icon: "BriefcaseIcon",
    route: "apps-licenses-folder",
    children: [
      {
        title: "message.licenses.licenseList",
        route: "apps-license-list",
      },
      {
        title: "message.licenses.licenseView",
        route: "apps-license-view",
      },      
    ],
  },  
];
