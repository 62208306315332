/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'show',
    data: [
      { title: 'access_control', show:'', route: { name: 'apps-access-control' }, icon: 'ShieldIcon', isBookmarked: false },
      { title: 'calendar', show:'', route: { name: 'apps-calendar' }, icon: 'CalendarIcon', isBookmarked: false },
      { title: 'kanban', show:'', route: { name: 'apps-kanban' }, icon: 'TrelloIcon', isBookmarked: false },
      { title: 'user_list', show:'', route: { name: 'apps-users-list' }, icon: 'UsersIcon', isBookmarked: false },
      { title: 'project_list', show:'', route: { name: 'apps-project-list' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'employee_list', show:'', route: { name: 'apps-employees-list' }, icon: 'TruckIcon', isBookmarked: false },
      { title: 'employee_cards', show:'', route: { name: 'apps-employees-cards' }, icon: 'TruckIcon', isBookmarked: false },
      { title: 'project_cards', show:'', route: { name: 'apps-projects-cards' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'commands', show:'', route: { name: 'apps-command' }, icon: 'FolderPlusIcon', isBookmarked: false },
      { title: 'purge', show:'', route: { name: 'apps-database-purge' }, icon: 'DatabaseIcon', isBookmarked: false },
      { title: 'params', show:'', route: { name: 'apps-param' }, icon: 'SettingsIcon', isBookmarked: false },
      { title: 'wiki', show:'', route: { name: 'apps-wiki' }, icon: 'GlobeIcon', isBookmarked: false },
      { title: 'timesheet_list', show:'', route: { name: 'apps-time-sheet-list' }, icon: 'ClockIcon', isBookmarked: false },
      { title: 'bill_list', show:'', route: { name: 'apps-bill-list' }, icon: 'DollarSignIcon', isBookmarked: false },
      { title: 'team_list', show:'', route: { name: 'apps-team-list' }, icon: 'UsersIcon', isBookmarked: false },
      { title: 'contract_list', show:'', route: { name: 'apps-contract-list' }, icon: 'FolderIcon', isBookmarked: false },
      { title: 'opportunity_list', show:'', route: { name: 'apps-opportunity-list' }, icon: 'TargetIcon', isBookmarked: false },
      { title: 'customer_list', show:'', route: { name: 'apps-customer-list' }, icon: 'BriefcaseIcon', isBookmarked: false },
      { title: 'expense_list', show:'', route: { name: 'apps-expense-list' }, icon: 'ArchiveIcon', isBookmarked: false },
      { title: 'invoice_list', show:'', route: { name: 'apps-invoice-list' }, icon: 'BookmarkIcon', isBookmarked: false },
      { title: 'notice_list', show:'', route: { name: 'apps-notice-list' }, icon: 'MessageSquareIcon', isBookmarked: false },
      { title: 'diary_list', show:'', route: { name: 'apps-diary-list' }, icon: 'UserIcon', isBookmarked: false },
      { title: 'audit_list', show:'', route: { name: 'apps-audit-list' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'task_list', show:'', route: { name: 'apps-task-list' }, icon: 'CheckSquareIcon', isBookmarked: false },
      { title: 'task_cards', show:'', route: { name: 'apps-task-cards' }, icon: 'CheckSquareIcon', isBookmarked: false },
      { title: 'feature_list', show:'', route: { name: 'apps-feature-list' }, icon: 'ToolIcon', isBookmarked: false },
      { title: 'jobs_list', show:'', route: { name: 'apps-jobs-list' }, icon: 'CodesandboxIcon', isBookmarked: false },
      { title: 'point_list', show:'', route: { name: 'apps-point-list' }, icon: 'ListIcon', isBookmarked: false },
      { title: 'sprint_list', show:'', route: { name: 'apps-sprint-list' }, icon: 'GridIcon', isBookmarked: false },
      { title: 'sprint_cards', show:'', route: { name: 'apps-sprint-cards' }, icon: 'GridIcon', isBookmarked: false },      
      { title: 'product_cards', show:'', route: { name: 'apps-product-cards' }, icon: 'BoxIcon', isBookmarked: false },  
      { title: 'todo', show:'', route: { name: 'apps-todo' }, icon: 'CheckSquareIcon', isBookmarked: false },       
      { title: 'asset_list', show:'', route: { name: 'apps-asset-list' }, icon: 'PackageIcon', isBookmarked: false },  
      { title: 'bank_list', show:'', route: { name: 'apps-bank-list' }, icon: 'DollarSignIcon', isBookmarked: false },  
      { title: 'account_list', show:'', route: { name: 'apps-account-list' }, icon: 'DollarSignIcon', isBookmarked: false },   
      { title: 'bugs', show:'', route: { name: 'apps-bugs' }, icon: 'TrelloIcon', isBookmarked: false },   
    ],
  },
}
/* eslint-enable */
