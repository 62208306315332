<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-img
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="$i18n.locale = localeObj.locale"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from "bootstrap-vue";
import store from "@/store";
import axios from "@axios";
import URL_API from "@core/utils/env";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },

  watch: {
    "$i18n.locale": function (newVal) {
      this.saveLocale(newVal);
    },
  },

  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      this.$i18n.locale = this.currentUserObj.locale;
      return this.locales.find((l) => l.locale === this.currentUserObj.locale);
    },
  },

  data() {
    return {
      //CurrentUser
      currentUserObj: store.state.user.userData,
    };
  },

  methods: {
    saveLocale(locale) {
      this.currentUserObj.locale = locale;

      axios({
        method: "put",
        url: `${URL_API}user/locale/${this.currentUserObj.id}/${locale}`,
        headers: {
          Authorization: "Bearer " + this.currentUserObj.token,
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: this.currentUserObj,
      });
    },
  },

  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: "en",
        img: require("@/assets/images/flags/en.png"),
        name: "Ingles",
      },
      {
        locale: "es",
        img: require("@/assets/images/flags/es.png"),
        name: "Espanhol",
      },
      {
        locale: "pt",
        img: require("@/assets/images/flags/pt.png"),
        name: "Português",
      },
    ];
    /* eslint-disable global-require */

    return {
      locales,
    };
  },
};
</script>

<style></style>
