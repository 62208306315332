<template>
  <div>
    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
    >
      <template #button-content>
        <div class="d-sm-flex d-none user-nav">
          <p class="user-name font-weight-bolder mb-0">
            {{ mountFirstName() }}
          </p>
          <span class="user-status">{{ userStatus() }}</span>
          <span class="user-status mmr" id="click-time">
            <feather-icon size="12" icon="ClockIcon" /> {{ minutes }} m
          </span>
          <b-tooltip
            custom-class="long-tooltip"
            target="click-time"
            triggers="hover"
            no-fade
          >
            {{ $t("sessionTimeOut") }} {{ minutes }} 
            <span v-if="minutes > 1">{{ $t("minutes") }}</span>
            <span v-else>{{ $t("minute") }}</span>
          </b-tooltip>
        </div>
        <!--:src="userData.avatar"-->
        <b-avatar
          v-if="userData.avatar.length > 5"
          size="40"
          v-bind:src="'data:image/jpeg;base64,'+userData.avatar"
          badge
          class="badge-minimal"
          badge-variant="success"
        >
          <feather-icon v-if="!mountFirstName()" icon="UserIcon" size="22" />
        </b-avatar>

        <b-avatar
          v-else
          size="40"
          :text="userData.avatar"
          badge
          class="badge-minimal"
          badge-variant="success"
        >
          <feather-icon v-if="!mountFirstName()" icon="UserIcon" size="22" />
        </b-avatar>        
      </template>

      <b-dropdown-item
        :to="{ name: 'pages-profile' }"
        link-class="d-flex align-items-center"
        v-if="userData.systemRole != 'ROLE_ADMIN' && userData.employeeId != 'organization-root'"
      >
        <feather-icon size="16" icon="UserIcon" class="mr-50" />
        <span> {{ $t("message.commands.profile") }} </span>
      </b-dropdown-item>

      <b-dropdown-item
        :to="{ name: 'apps-kanban' }"
        link-class="d-flex align-items-center"
        v-if="userData.systemRole != 'ROLE_ADMIN' && userData.employeeId != 'organization-root'"
      >
        <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
        <span>{{ $t("message.tasks.tasks") }}</span>
      </b-dropdown-item>

      <b-dropdown-item @click="showTour" link-class="d-flex align-items-center" v-if="userData.systemRole != 'ROLE_ADMIN' && userData.employeeId != 'organization-root'">
        <feather-icon size="16" icon="HelpCircleIcon" class="mr-50" />
        <span>{{ $t("tour") }}</span>
      </b-dropdown-item>

      <b-dropdown-divider v-if="userData.systemRole != 'ROLE_ADMIN' && userData.employeeId != 'organization-root'"/>

      <b-dropdown-item
        :to="{ name: 'pages-account-setting' }"
        link-class="d-flex align-items-center"
        v-if="userData.systemRole != 'ROLE_ADMIN'"
      >
        <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
        <span>{{ $t("message.commands.settings") }}</span>
      </b-dropdown-item>
      <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
        <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
        <span>{{ $t("message.commands.logoff") }}</span>
      </b-dropdown-item></b-nav-item-dropdown
    >

    <b-modal
      hide-header-close
      id="modal-token"
      modal-class="modal-danger"
      centered
      title="Sessão expirada"
      ok-title="Continuar"
      cancel-title="Sair"
      @ok="refreshToken()"
      @cancel="logout()"
      no-close-on-backdrop
    >
      <b-card-text>
        Deseja continuar navegando ?
        <b style="color: red">({{ countDown }})</b></b-card-text
      >
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */

import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BTooltip,
  BCardText,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";
import AppTour from "@core/components/app-tour/AppTour.vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import store from "@/store";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    AppTour,
    BTooltip,
    BCardText,
  },

  data() {
    return {
      userData: store.state.user.userData,
      employeeData: store.state.user.employeeData,
      avatarText,
      fullName: "",
      employee: null,

      expireDate: "",
      minutes: 0,
      countDown: store.state.user.paramData.timeToExpireModalToken,
      stayOn: false,

      interval: null,
      finishCounter: false,
    };
  },

  async created() {
    this.finishCounter = false;
    await this.getData();
    await this.updateClock();

    this.interval = window.setInterval(() => {this.updateClock();}, 60000);
  },

  watch: {
    locale(val) {
      this.$i18n.locale = val;
    },
  },

  destroyed() {
    clearInterval(this.interval)
  },

  mounted() {
    if (!this.fullName) {
      this.fullName = this.employeeData.data.fullName;
    }

    /*this.$root.$on("updateAvatar", (avatar) => {
      this.userData.avatar = avatar;
    });*/
    this.$root.$on("updateName", (fullName) => {
      this.fullName = fullName;
    });
  },

  methods: {
    mountFirstName() {
      if (!this.fullName)
        return this.employeeData.data.fullName.split(" ").slice(0, 2).join(" ");
      else return this.fullName.split(" ").slice(0, 2).join(" ");
    },

    countDownTimer() {
      if (this.countDown > 0) {
        this.createCountDown();
      } else if (this.stayOn === false) {
        this.finishCounter = true;
        this.logout();
      } else {
        this.stayOn = false;
        this.countDown = store.state.user.paramData.timeToExpireModalToken;
      }
    },

    createCountDown() {
      this.interval = setInterval(() => {
        if (this.countDown === 0) {
          clearInterval(this.interval);
          this.countDownTimer();
        } else {
          this.countDown--;
        }
      }, 1000);
    },

    refreshToken() {
      useJwt
        .login({
          email: store.state.user.userData.email,
          password: store.state.user.password,
        })
        .then((response) => {
          useJwt.setToken(response.data.token);
          useJwt.setRefreshToken(response.data.refreshToken);
          this.loadData();
        })
        .catch((error) => {
          console.log(error);
          this.$refs.loginForm.setErrors(error.response.data.error);
        });
    },

    async loadData() {
      this.stayOn = true;
      await this.getData();
      await this.updateClock();
    },

    showTour() {
      this.$root.$refs.Overview.reOpenModal();
    },

    async getData() {
      await axios
        .get(`${URL_API}token/${useJwt.getToken()}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.expireDate = response.data;
        });
    },

    async updateClock() {
      var today = new Date();
      var tokenValue = new Date(this.expireDate);
      var diffMs = tokenValue - today;
      var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

      this.minutes = diffMins;

      if (this.minutes <= 0 && !this.finishCounter) {
        this.$root.$emit("bv::show::modal", "modal-token");
        this.countDownTimer();
      }
    },

    userStatus() {
      if (this.employeeData.data.projectRole == "PROGRAMMER") {
        return "Programador";
      }
      if (this.employeeData.data.projectRole == "TESTER") {
        return "Testador";
      }
      if (this.employeeData.data.projectRole == "MANAGER") {
        return "Gerente";
      }
      if (this.employeeData.data.projectRole == "ANALYST") {
        return "Analista";
      }
      if (this.employeeData.data.projectRole == "ARCHITECT") {
        return "Arquiteto";
      }
      if (this.employeeData.data.projectRole == "TRAINEE") {
        return "Estágiario";
      }
      if (this.employeeData.data.projectRole == "UNDEFINED") {
        return "Indefinido";
      }
    },

    async logout() {

      clearInterval(this.interval);

      this.finishCounter = true;
      this.countDown = 0;
     

      // Redirect to login page
      await axios({
        method: "post",
        url: `${URL_API}auth/logout`,
        headers: getHeader(this.userData),
      }).then((response) => {

         // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");
      localStorage.removeItem("employeeData");
      localStorage.removeItem("accessControl");
      localStorage.removeItem("paramData");

      // Reset ability
      this.$ability.update(initialAbility);

      
        this.$router.push({ name: "auth-login" });
      })
    },
  },
};
</script>
<style lang="scss">
.mmr {
  margin-top: 4px !important;
}
</style>
