/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import projectManagement from "./project-management";
import planning from "./planning";
import people from "./people";
import companies from "./companies";
import business from "./business";
import system from "./system";
import version from "./version";
import database from "./database";

// Array of sections
export default [
  ...projectManagement,
  ...planning,
  ...people,
  ...companies,
  ...business,
  ...database,
  ...system,
  ...version,
];
