<template>
  <b-nav-item @click="skin = isDark ? 'light' : 'dark'">
    <feather-icon size="21" :icon="`${isDark ? 'Sun' : 'Moon'}Icon`" />
  </b-nav-item>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import { computed } from "@vue/composition-api";
import { BNavItem } from "bootstrap-vue";
import store from "@/store";
import axios from "@axios";
import URL_API from "@core/utils/env";

export default {
  components: {
    BNavItem,
  },

  data() {
    return {
      //CurrentUser
      currentUserObj: store.state.user.userData,
    };
  },

  watch: {
    skin: function () {
      axios({
        method: "put",
        url: `${URL_API}user/theme/${this.currentUserObj.id}/${this.skin}`,
        headers: {
          Authorization: "Bearer " + this.currentUserObj.token,
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: this.currentUserObj,
      });
    },
  },

  setup() {
    const { skin } = useAppConfig();

    var currentUserObj = store.state.user.userData;
    skin.value = currentUserObj.theme;

    const isDark = computed(() => skin.value === "dark");

    return { skin, isDark };
  },
};
</script>
