export default [
  {
    header: "Planning",
    route: "apps-planning-header",
  },
  {
    title: "Calendar",
    route: "apps-calendar",
    icon: "CalendarIcon",
  },
  {
    title: "todo",
    route: "apps-todo",
    icon: "CheckSquareIcon",
  },
];
